import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStateValue } from "../State"
import { Link } from "gatsby"
import NsfwListingItem from "../NsfwListingItem"
import "./ArticleListingItem.sass"

const ArticleListingItem = (props) => {

	const [state] = useStateValue()
	const showNsfwContent = state.nsfw.show
	const {
		tileImage,
		featuredImage,
		hasVideo,
		category,
		collection,
		title,
		abstract,
		timeToRead,
		isNsfw,
		slug,
		featured
	} = props

	const tileImageActual = tileImage ? tileImage : featuredImage

	if (isNsfw && !showNsfwContent) {

		return <NsfwListingItem /> 

	} 

	const image = getImage(tileImageActual)

	return (

		<Link className="article-listing-item" to={slug}  >

			{tileImageActual && <GatsbyImage alt={title} title={title} className="featured-image" image={image} />}

			{(hasVideo && tileImageActual)
				&& <div className="has-video">Video</div>}

			<div className="content">

				<div className="category">{category}</div>

				{
					(collection && (collection !== category))
					&& <div className="collection">{collection}</div>
				}

				{featured ?
					<h2>{title}</h2>
					:
					<h3>{title}</h3>
				}

				<p>{abstract}</p>

				<div className="meta">

					{isNsfw && <span className="nsfw-tag">Not safe for work</span>}
					<span className="time-to-read">Reading time <strong>{timeToRead}min</strong></span>

				</div>

			</div>

		</Link>

	)


}

export default ArticleListingItem