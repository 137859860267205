/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../../modules/useSiteMetadata"

interface Props {

	description?: string,
	lang?: string,
	keywords?: [],
	title?: string,
	image?: string,
	pathname?: string,
	titleTemplate?: string,

}

const Seo = (props:Props) => {

	const { description, lang, keywords, title, image, pathname, titleTemplate, } = props
	const siteMetadata = useSiteMetadata()
	const siteUrl:string = siteMetadata.siteUrl
	const url:string  = siteUrl + pathname || "/"
	const metaDescription:string = description || siteMetadata.siteDescription
	const allKeywords:string = keywords ? keywords.concat(siteMetadata.baseKeywords).join(`, `) : siteMetadata.baseKeywords.join(`, `)

	let metaData = [
		{
			name: `description`,
			content: metaDescription,
		},
		{
			property: `og:title`,
			content: title,
		},
		{
			property: `og:url`,
			content: url,
		},
		{
			property: `og:description`,
			content: metaDescription,
		},
		{
			property: `og:type`,
			content: `website`,
		},
		{
			name: `twitter:card`,
			content: `summary`,
		},
		{
			name: `twitter:creator`,
			content: siteMetadata.author,
		},
		{
			name: `twitter:title`,
			content: title,
		},
		{
			name: `twitter:description`,
			content: metaDescription,
		},
	].concat(allKeywords.length > 0 ? { name: `keywords`, content: allKeywords, } : [], )
	
	if (image) {

		metaData = metaData.concat(
			{
				property: `og:image`,
				content: siteUrl + image,
			}
		)

	}

	return <Helmet
		htmlAttributes={{
			lang,
		}}
		title={title}
		titleTemplate={titleTemplate || `%s | ${siteMetadata.siteTitle}`}
		link={[
			{
				type: "text/plain",
				rel: "author",
				href: siteUrl + "/humans.txt",
			}
		]}
		meta={metaData}/>

}

export default Seo
