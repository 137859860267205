import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Seo from "../../components/Seo/Seo"
import ArticleListing from "../../components/ArticleListing"
import "../index.sass"
import PageTransition from "../../components/PageTransition"
import PageHeader from "../../components/PageHeader"

const OpinionPage = (props) => {

	const { data } = props
	const posts = data.allMarkdownRemark.edges

	return (

		<PageTransition {...props}>

			<Seo title="Discourse" keywords={[]} />
			<PageHeader title="Discourse" description="Conversations with interesting people about life, ideas and belifs." />

			<div className="homepage">

				<ArticleListing posts={posts} />

			</div>

		</PageTransition>

	)

}

OpinionPage.propTypes = {

	data: PropTypes.object.isRequired,

}

export default OpinionPage

export const pageQuery = graphql`

	query {
		allMarkdownRemark(

			filter: {
				fields: {
					category: {eq: "Discourse"}
				}
				frontmatter: {
					draft: {ne: true}
				}
			}

			sort: {
				order: DESC, fields: [frontmatter___articlePublicationDate]
			}

		) {
		edges {
				...indexListing
			}
		}
	}

`