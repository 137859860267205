import React from 'react'
import { useStateValue } from '../State';
import * as style from "./NsfwListingItem.module.sass"

const NsfwListingItem = () => {

	const [state, dispatch] = useStateValue()

	const toggleShowNSFW = () => {

		if (state.nsfw.show) {

			dispatch({ type: 'DISABLE_NSFW' })

		} else {

			dispatch({ type: 'ENABLE_NSFW' })

		}

	}
	
	return (

		<div className={style.NsfwListingItem}>

			<div>

				<h4>NSFW</h4>
				<p>This post is marked as not safe for work</p>
				<button onClick={toggleShowNSFW}>Show all NSFW content</button>

			</div>

		</div>

	)


}

export default NsfwListingItem