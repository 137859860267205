/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import ArticleListingItem from "../ArticleListingItem"
import { motion, AnimatePresence } from "framer-motion"
import * as style from "./ArticleListing.module.sass"

interface ArticleListingProps {

	skipPostID?: string
	posts: any

}

const ArticleListing: React.FC<ArticleListingProps> = ({skipPostID, posts}) => {

	const itemAnimationStates = {
		initial: {
			opacity: 0,
			y: -50
		},
		animate: {
			opacity: 1,
			y: 0,
		}
	}

	return (

		<div className={style.ArticleListing}>
			<AnimatePresence>
				{posts.filter(post => post.node.frontmatter.title.length > 0).map(({ node: post }, i) => {

					if (post.id !== skipPostID) {

						const { frontmatter, fields, timeToRead } = post

						return (
							<motion.div key={fields.slug} transition={{ delay: i * .07 }} variants={itemAnimationStates} initial="initial" animate="animate">
								<ArticleListingItem {...fields} {...frontmatter} timeToRead={timeToRead} />
							</motion.div>
						)
					
					}
					return null
				
				})}
			</AnimatePresence>
		</div>

	)

}

export default ArticleListing
