import React from "react"
import clsx from "clsx"
import * as style from "./PageHeader.module.sass"

interface PageHeaderProps {
	title: string
	sectionName?: string
	description?: string
	className?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
	title,
	description,
	sectionName,
	className,
}) => {

	const classNames = clsx([
		className,
		style.PageHeader,
		"PageHeader"
	])

	return (
		<>
			<header data-testid='PageHeader' className={classNames}>
				<h1>
					{sectionName && <span>{sectionName}</span>}
					{title}
				</h1>
				{description && <p>{description}</p>}
			</header>

		</>
	)

}

export default PageHeader
