import { useStaticQuery, graphql } from "gatsby"

interface SiteMetadata {

	siteUrl: string
	siteTitle: string
	siteTagline: string
	siteDescription: string
	baseKeywords: []
	author: string
	googleAnalyticsTrackingID: string
	social: {

		youTube: string
		facebook: string
		instagram: string
		twitter: string
		meetup: string

	}

}


export const useSiteMetadata = ():SiteMetadata => {

	const { site } = useStaticQuery(

		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						siteUrl
						siteTitle
						siteTagline
						author
						googleAnalyticsTrackingID
						siteDescription
						baseKeywords
						social {
							youTube
							facebook
							instagram
							twitter
							meetup
						}
					}
				}
			}
        `

	)

	return site.siteMetadata

}